import React from 'react'

import Layout from '../components/layout'

import { PhotoSwipeGallery } from 'react-photoswipe'

import g101 from '../images/gallery/g101.jpg'
import g103 from '../images/gallery/g103.jpg'
import g104 from '../images/gallery/g104.jpg'
import g105 from '../images/gallery/g105.jpg'

import g201 from '../images/gallery/g201.jpg'
import g202 from '../images/gallery/g202.jpg'
import g203 from '../images/gallery/g203.jpg'
import g204 from '../images/gallery/g204.jpg'

import g301 from '../images/gallery/g301.jpg'
import g302 from '../images/gallery/g302.jpg'

import g401 from '../images/gallery/g401.jpg'
import g402 from '../images/gallery/g402.jpg'
import g403 from '../images/gallery/g403.jpg'
import g404 from '../images/gallery/g404.jpg'
import g405 from '../images/gallery/g405.jpg'

import g501 from '../images/gallery/g501.jpeg'

import 'react-photoswipe/lib/photoswipe.css'

import '../styles/common-styles.css'
import '../styles/layout-styles.css'
import '../styles/photos-styles.css'

const Photos = () => {
  const galleryItems = [
    {
      src: g501,
      thumbnail: g501,
      w: 1260,
      h: 900,
    },
    {
      src: g401,
      thumbnail: g401,
      w: 1260,
      h: 900,
    },
    {
      src: g402,
      thumbnail: g402,
      w: 1260,
      h: 900,
    },
    {
      src: g403,
      thumbnail: g403,
      w: 1100,
      h: 900,
    },
    {
      src: g404,
      thumbnail: g404,
      w: 1100,
      h: 900,
    },
    {
      src: g405,
      thumbnail: g405,
      w: 1260,
      h: 900,
    },
    {
      src: g301,
      thumbnail: g301,
      w: 1200,
      h: 900,
    },
    {
      src: g302,
      thumbnail: g302,
      w: 1100,
      h: 900,
    },
    {
      src: g101,
      thumbnail: g101,
      w: 746,
      h: 961,
    },
    {
      src: g103,
      thumbnail: g103,
      w: 1200,
      h: 900,
    },
    {
      src: g104,
      thumbnail: g104,
      w: 1200,
      h: 900,
    },
    {
      src: g105,
      thumbnail: g105,
      w: 1200,
      h: 900,
    },
    {
      src: g201,
      thumbnail: g201,
      w: 1200,
      h: 900,
    },
    {
      src: g202,
      thumbnail: g202,
      w: 1200,
      h: 900,
    },
    {
      src: g203,
      thumbnail: g203,
      w: 1300,
      h: 900,
    },
    {
      src: g204,
      thumbnail: g204,
      w: 1200,
      h: 900,
    },
  ]

  let galleryOptions = {
    //http://photoswipe.com/documentation/options.html
  }

  const getThumbnailContent = (item) => {
    return <img src={item.thumbnail} width={item.w / 4} height={item.h / 4} />
  }

  return (
    <Layout>
      <div className='text-3xl my-6 text-nittanyNavy text-center md:my-14 lg:text-6xl'>
        Photos
      </div>
      <div className='galleryWrapper'>
        <PhotoSwipeGallery
          items={galleryItems}
          options={galleryOptions}
          thumbnailContent={getThumbnailContent}
        />
      </div>
    </Layout>
  )
}

export default Photos
